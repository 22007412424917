@use "../../styles/variables" as *;

.blank-tile {
  height: 3.875rem;
  width: 3.875rem;
  perspective: 1000px;

  &--bounce {
    animation: bounce 1.2s ease 2 forwards;
  }
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }

  .blank-tile__inner {
    position: relative;
    height: 100%;
    width: 100%;
    transition: transform 0.8s;
    -webkit-transition: -webkit-transform 0.8s;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;


    &--flip {
      transform: rotateX(180deg);
      -webkit-transform: rotateX(180deg);
    }

    .blank-tile__guess,
    .blank-tile__final {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .blank-tile__guess {
      border: 2px solid $col-lg;

      &--fill {
        border: 2px solid $col-dg;
        animation: pulse-animation 0.1s;
      }

      @keyframes pulse-animation {
        0% {
          box-shadow: 0 0 0 0px rgba(47, 49, 49, 0);
        }
        100% {
          box-shadow: 0 0 0 1px rgba(47, 49, 49, 1);
        }
      }
    }

    .blank-tile__final {
      transform: rotateX(180deg);
      -webkit-transform: rotateX(180deg);


      &--green {
        background-color: $col-grn;
        color: $col-wht;
      }

      &--yellow {
        background-color: $col-ylw;
        color: $col-wht;
      }

      &--grey {
        background-color: $col-dg;
        color: $col-wht;
      }
    }

    .blank-tile__value {
      font-weight: bold;
      font-size: 2rem;
    }
  }
}
