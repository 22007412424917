.game-over {
  position: relative;
  z-index: 1000;
  display: flex;
  justify-content: center;
  visibility: hidden;

  &--show {
    visibility: unset;
  }

  &__text {
    background-color: black;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    color: white;
    font-weight: bold;
  }
}
