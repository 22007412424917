* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
