.keyboard {
  height: 50%;
  display: flex;
  gap: 0.4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__row {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
  }
}
