@use "../../styles/variables" as *;

.key-tile {
  background-color: $col-lg;
  border-radius: 5px;
  width: 3rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }

  &--symbol {
    width: 4.5rem;
  }

  &--green {
    background-color: $col-grn;
    color: $col-wht;
  }

  &--yellow {
    background-color: $col-ylw;
    color: $col-wht;
  }

  &--grey {
    background-color: $col-dg;
    color: $col-wht;
  }
}

@media only screen and (max-width: 568px) {
  .key-tile {
    width: 2rem;
    font-size: 0.8rem;

    &--symbol {
      width: 3rem;
    }
  }
}
